<template>
  <div class="card">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SmCard',
};
</script>

<style lang="scss">
.card {
  padding: 20px;

  box-shadow: 0px 0px 20px rgba(var(--rgb-blue), 0.09);
}
</style>
