<template>
  <section class="auth">
    <div class="auth__header">
      <router-link class="auth__logo" to="">
        <img
          class="auth__image"
          src="@/assets/images/logo.svg"
          alt="Научно-Исследовательский Центр Системы управления"
        />
      </router-link>
    </div>
    <sm-card class="auth-card">
      <h2 class="auth-card__title">Вход в личный кабинет</h2>
      <form
        ref="authForm"
        class="auth-form"
        method="post"
        @submit.prevent="onSubmit"
      >
        <sm-input
          v-model.trim="authForm.login"
          class="auth-form__input"
          autocomplete="on"
          placeholder="Логин"
        />
        <!-- label="Логин" -->

        <sm-input
          v-model.trim="authForm.password"
          class="auth-form__input"
          autocomplete="on"
          type="password"
          placeholder="Пароль"
        />
        <!-- label="Пароль" -->

        <sm-checkbox
          v-model="authForm.remember"
          class="auth-form__checkbox"
          label="Запомнить меня"
        />

        <sm-button
          class="auth-form__button"
          :disabled="!this.isValidAuthForm"
          :isLoading="this.user.isLoading"
          >Войти</sm-button
        >
      </form>
    </sm-card>
  </section>
</template>

<script>
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmCard from '@/components/common/cards/SmCard.vue';
import SmInput from '@/components/common/forms/SmInput.vue';
import SmCheckbox from '@/components/common/forms/SmCheckbox.vue';
import SmButton from '@/components/common/buttons/SmButton.vue';

export default {
  name: 'Login',

  components: {
    SmCard,
    SmButton,
    SmInput,
    SmCheckbox,
  },

  data() {
    return {
      authForm: {
        login: '',
        password: '',
        remember: false,
      },
      isAutofillForm: false,
      watcherForAuthForm: null,
    };
  },

  computed: {
    ...mapState({
      user: (state) => state.account.user,
    }),

    isValidAuthForm() {
      const { login, password } = this.authForm;
      return this.isAutofillForm
        ? this.isAutofillForm
        : !!(login.length && password.length);
    },
  },

  mounted() {
    const loginInput = this.$refs.authForm[0];
    const passwordInput = this.$refs.authForm[1];

    const checkAutofill = () => {
      const loginInputAutofill = loginInput.matches(
        'input[type="text"]:-webkit-autofill'
      );
      const passwordInputAutofill = passwordInput.matches(
        'input[type="password"]:-webkit-autofill'
      );
      if (loginInputAutofill && passwordInputAutofill) {
        clearInterval(refreshInterval);
        this.isAutofillForm = true;
      }
    };

    let refreshInterval = setInterval(() => checkAutofill(), 50);

    this.watcherForAuthForm = this.$watch(
      'authForm',
      () => {
        this.isAutofillForm = false;
        this.watcherForAuthForm = null;
      },
      { deep: true }
    );
  },

  methods: {
    ...mapActions({
      login: 'account/login',
      getUserPermissions: 'account/getUserPermissions',
      getItem: 'editableList/getItem',
    }),

    onSubmit() {
      this.isPocessingAuth = true;
      this.login(this.authForm).then(() => {
        if (this.user.error && this.user.error.length) return;

        this.getItem({
          name: 'AccountEmployees',
          id: localStorage.getItem('accountId'),
        }).then((state) => {
          localStorage.setItem('hidePersonalData', state?.hidePersonalData);
        });

        this.getUserPermissions().then(() => {
          this.$router.push({ name: 'Index' });
          this.isPocessingAuth = false;
        });
      });
    },
  },
};
</script>

<style lang="scss">
.auth {
  padding: 40px 20px 20px;
}

.auth-card {
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 40px;
  max-width: 410px;
}

.auth-card__title {
  margin-bottom: 40px;

  font-size: 24px;
  font-weight: 500;
}

.auth__header {
  text-align: center;
  padding-bottom: 32px;
}

.auth__logo {
  display: inline-block;
}

.auth__image {
  max-width: 198px;
}

.auth-form__title {
  margin-top: 0;
  margin-bottom: 40px;

  font-size: 24px;
  font-weight: 600;
}

.auth-form__input {
  margin-bottom: 16px;
}

.auth-form__checkbox {
  margin-bottom: 16px;
}

.auth-form__checkbox .checkbox__label {
  font-size: 14px;
  color: var(--gray);
}
</style>
